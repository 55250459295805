import * as Sentry from '@sentry/react';
import { observer } from 'mobx-react-lite';
import { NextParsedUrlQuery } from 'next/dist/server/request-meta';
import { useRouter } from 'next/router';
import { ComponentType, useEffect, useMemo, useState } from 'react';

import PageLoading from 'src/components/common/page-loading/page-loading';
import { routes } from 'src/constants/navigation';
import { compareDatesInHours } from 'src/utils/compareDates';

import { useStores } from '../../common/root-store-provider/root-store-provider';

const lastActiveStep =
  typeof window !== 'undefined' &&
  window.localStorage.getItem('lastActiveStepIndex');

const OUTDATED_USER_SESSION_HOURS = 3;

const page = <T extends object>(Component: ComponentType<T>) =>
  observer((props: T) => {
    const [mounted, setMounted] = useState(false);
    const {
      authStore: {
        variant,
        setVariant,
        fetchUser,
        auth_token,
        user,
        logOut,
        campaign,
        setCampaign,
      },
      quizStore,
      analyticsStore: {
        segment,
        getAbTests,
        reset: resetAnalytics,
        isFetching,
      },
      paymentsStore,
    } = useStores();
    const router = useRouter();
    const { pathname, query, isReady } = router;

    const campaignName = query['utm_campaign'];

    const emailToken =
      typeof query['email_token'] === 'string'
        ? query['email_token']
        : undefined;

    // biome-ignore lint/correctness/useExhaustiveDependencies: Component is not a pure function
    const result = useMemo(
      () =>
        !mounted || user === undefined ? (
          <PageLoading />
        ) : (
          <Component {...props} />
        ),
      [mounted, user, props],
    );

    const variantFromQuery = query['variant'];
    const hardReset = query['hard_reset'];

    const resetQuizAndLogout =
      pathname === routes.quiz && isReady && (!!query['reset'] || !!hardReset);

    useEffect(() => {
      if (
        campaignName &&
        campaign !== campaignName &&
        typeof campaignName === 'string'
      ) {
        setCampaign(campaignName);
      }
    }, [campaign, campaignName, setCampaign]);

    useEffect(() => {
      setMounted(true);
    }, []);

    useEffect(() => {
      if (emailToken === undefined && variant !== 'emailtraf') {
        fetchUser({ token: auth_token })
          .then((r) => r)
          .catch((error) => {
            if (error.name === 'AbortError') {
              console.log('AbortError: Fetch request aborted');
              return;
            }
            Sentry.captureException(error.name);
          });
      }
    }, [fetchUser, auth_token, emailToken, variant]);

    useEffect(() => {
      if (
        variantFromQuery &&
        variant !== variantFromQuery &&
        typeof variantFromQuery === 'string'
      ) {
        setVariant(variantFromQuery);
      } else if (
        variantFromQuery === undefined &&
        variant !== undefined &&
        (!!query['reset'] || !!hardReset)
      ) {
        setVariant(undefined);
      }
    }, [query, setVariant, variantFromQuery, variant, hardReset]);

    const isUserSessionOutdated = useMemo(() => {
      if (variantFromQuery !== variant) {
        return true;
      }

      const firstLoginDateFromStorage =
        typeof window !== 'undefined' &&
        window.localStorage.getItem('firstLoginDateAndTime');

      return (
        compareDatesInHours(firstLoginDateFromStorage) >=
        OUTDATED_USER_SESSION_HOURS
      );
    }, [variantFromQuery, variant]);

    useEffect(() => {
      if (resetQuizAndLogout && (isUserSessionOutdated || hardReset)) {
        logOut().then((r) => r);
        quizStore.reset();
        resetAnalytics();
        paymentsStore.resetPayment();
        localStorage.clear();
        sessionStorage.clear();
      }
    }, [
      hardReset,
      isUserSessionOutdated,
      logOut,
      paymentsStore,
      quizStore,
      resetAnalytics,
      resetQuizAndLogout,
    ]);

    // biome-ignore lint/correctness/useExhaustiveDependencies: https://github.com/vercel/next.js/issues/18127
    useEffect(() => {
      if (resetQuizAndLogout && !user) {
        const { pathname, query } = router;
        const newQuery: NextParsedUrlQuery = {
          ...query,
          step: lastActiveStep && !isUserSessionOutdated ? lastActiveStep : '0',
        };
        delete newQuery['reset'];
        delete newQuery['hard_reset'];
        router.replace({ pathname, query: newQuery }).then((r) => r);
      }
    }, [resetQuizAndLogout, user]);

    useEffect(() => {
      if (!segment && !isFetching && query['reset'] === undefined && isReady) {
        getAbTests({
          variant: variant ?? '',
          utm_source: (query['utm_source'] as string) ?? '',
          utm_campaign: (query['utm_campaign'] as string) ?? '',
          region: (query['region'] as string) ?? '',
        }).then((r) => r);
      }
    }, [getAbTests, isFetching, isReady, query, segment, variant]);

    // The check on `mounted` helps avoid hydration DOM mismatch
    // that happens because `user` is always empty during SSR,
    // but may be `null` if the user is not logged in
    return result;
  });

export default page;
