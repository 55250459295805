import dayjs from 'dayjs';

export const compareDatesInHours = (firstDateString: unknown): number => {
  if (
    typeof firstDateString !== 'string' ||
    !firstDateString ||
    firstDateString.length === 0
  ) {
    return 0;
  }
  const firstDate = dayjs(new Date(firstDateString));
  const currentDate = dayjs();

  const diffInHours = currentDate.diff(firstDate, 'hour', true);
  return Math.floor(diffInHours);
};
